import '/assets/styles/pages/checkout-summary.scss';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Loader from '/views/partials/loader';
import Pricebox from '/views/partials/pricebox';

// Context
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";
import { useParams } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Services
import bookingServices from '/services/booking';

const parseHead = (event) => {
	if(event?.calendar?.full_date) {
		const eventDate = formatDate(event.calendar.full_date, 'd MMMM');
		return {
			title: `Bilet Özeti - ${event.title} / ${eventDate} ${event.calendar.event_location}`,
			description: `${eventDate} tarihli ${event.calendar.event_location} ${event.title} biletleriniz.`,
			image: event.image?.asset_paths?.original,
		}
	}
	
	return {
		title: 'Bilet Özeti'
	}
}

const Checkout = () => {
	const { setHead } = useHead();
	const { triggerEvent } = useGlobalEvents();
	const { setNotFound } = useGlobalState();
	const { id: bookingId } = useParams('checkoutSummary');
	const [bookingData, setBookingData] = useState(null);

	const previousBookings = useMemo(() => {
		try {
			const bookingStorageData = JSON.parse(window.localStorage.getItem(process.config.checkout.checkoutCompleteSaleIdsPersistenceKey) ?? '[]');

			return bookingStorageData;
		}
		catch(e) {
			return []
		}
	}, [])

	const booking = useMemo(() => {
		return bookingData ? {
			...bookingData,
			event: {
				...bookingData.event,
				dateTimeString: formatDate(bookingData.event.calendar.full_date, 'd MMMM y - HH:mm'),
			},
		} : bookingData;
	}, [bookingData])

	useEffect(() => {
		if(booking?.event) {
			setHead(parseHead(booking.event));
		}
	}, [booking])

	useEffect(() => {
		if(bookingId && booking?.id?.toString?.() !== bookingId) {
			bookingServices.getBooking(bookingId).then(bookingData => {
				if(bookingData.status === 1) {
					setBookingData(bookingData);
				}
				else {
					setNotFound()
				}
			}).catch(() => {
				setNotFound();
			})
		}
	}, [bookingId, booking]);

	useEffect(() => {
		if(booking && !previousBookings.includes(booking.id)) {
			window.localStorage.setItem(process.config.checkout.checkoutCompleteSaleIdsPersistenceKey, JSON.stringify([
				...previousBookings,
				booking.id,
			]));

			triggerEvent('completeCheckout', { sale: booking });
		}
	}, [booking, previousBookings])

	return (
		<div className="checkout-summary-wrap wrapper">
			<main className="checkout-summary loader-container">
				<Loader
					message="Bilet bilgileriniz getiriliyor..."
					loading={!booking} />
				{booking &&
					<>
						<h1 className="summary-title"><Icon className="title-icon" name="comment-check" />Tebrikler</h1>
						<div className="summary-pnrinfo">
							<strong>{booking.code}</strong> numaralı işleminiz başarıyla tamamlanmıştır.
						</div>
						<div className="summary-info">
							<Icon name="ticket" />
							<span>Etkinlik günü görevlilierimize kayıtlı telefon numaranızı söyleyerek veya olıuşturacağınız QR Kodunu göstererek etkinliğe giriş yapabilirsiniz.</span>
						</div>

						<div className="summary-event">
							<Img
								className="event-image"
								src={booking.event.image?.asset_paths?.mini} />
							<div className="event-textarea">
								<strong className="event-title">{booking.event.title}</strong>

								<div className="event-infoline">
									<Icon name="time" /> {booking.event.dateTimeString}
								</div>
								<div className="event-infoline">
									<Icon name="map-pin" /> {booking.event.calendar.event_location}
								</div>
								{!!booking.event.calendar.door_time?.length &&
									<div className="event-infoline">
										<Icon name="door" /> Kapı Açılışı - {booking.event.calendar.door_time}
									</div>
								}
								{/* {booking.event.prevEvent &&
									<div className="event-infoline">
										<Icon name={booking.event.prevEvent.icon} /> {booking.event.prevEvent.relation}: {booking.event.prevEvent.title} - {booking.event.prevEvent.time}
									</div>
								}
								{booking.event.nextEvent &&
									<div className="event-infoline">
										<Icon name={booking.event.nextEvent.icon} /> {booking.event.nextEvent.relation}: {booking.event.nextEvent.title} - {booking.event.nextEvent.time}
									</div>
								} */}
							</div>
						</div>

						<div className="summary-tickets">
							<strong className="tickets-title">Biletleriniz:</strong>

							<ul className="tickets-list">
								{booking.products.map((ticket, nth) => (
									<li className="tickets-ticket" key={nth}>
										<span className="ticket-title">
											{ticket.option && <>{ticket.option.title} - </>}{ticket.option_slot?.title}
										</span>
										<Pricebox
											className="ticket-price"
											price={ticket.price} />
									</li>
								))}
							</ul>
						</div>

						<ul className="summary-prices">
							<li className="prices-item">
								<span>Bilet Fiyatı</span>
								<Pricebox className="item-price" price={booking.product_total_price} />
							</li>
							{booking.extras.map((extra) => (
								<li className="prices-item" key={extra.id}>
									<span>{extra.title}</span>
									<Pricebox className="item-price" price={extra.price} />
								</li>
							))}
							<li className="prices-item total">
								<span>Toplam</span>
								<Pricebox className="item-price" price={booking.total_price} />
							</li>
						</ul>

						{/* <div className="summary-controls">
							<Btn icon="envelope" className="controls-cta text outline">
								E-Posta Gönder
							</Btn>
							<Btn icon="pdf" className="controls-cta text outline">
								PDF Olarak İndir
							</Btn>
						</div> */}
					</>
				}
			</main>
		</div>
	)
}

Checkout.propTypes = {
	pageProps: PropTypes.object
}

export default Checkout